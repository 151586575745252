export default {
  title: {
    login: "%{school} - Login",
    registration: "%{school} - Registration",
    subjects: "%{school} - Select subject",
    subject: "%{school} - Subject",
    offer: "%{school} - Offer",
    home: "%{school} - Dashboard",
    dictionary: "%{school} - Dictionary",
    events: "%{school} - Events",
    news: "%{school} - News",
    profile: "%{school} - Profile",
    learn: "%{school} - Learning",
    pay: "%{school} - Subject purchasing",
    checkInSystem: "Are you already registered?", // todo
    setPassword: "Enter password to login.", // todo
    createAccount: "Create an account in the system.", // todo
  },
  email: "Email",
  personalLink: "Personal link", // toDo
  login: "Login",
  name: "Name",
  phone: "Phone", // toDo
  aboutMe: "About me", // toDo
  registration: "Registration",
  password: "Password",
  currentPassword: "Current password",
  newPassword: "New password",
  confirmPassword: "Confirm password",
  confirmNewPassword: "Confirm new password",
  forgotPassword: "Forgot your password?",
  passwordsMatch: "Passwords do not match",
  resetPassword: "Mot de passe a bien été reinitialisé, vérifiez votre email pour plus de détails.",
  resetConfirm: "Do you want to reset your password?",
  enter: "Enter",
  studentEnter: "Student enter", //toDo
  check: "Check",
  emailIsNotRegistered: "Email is not Registered",
  logout: "Logout",
  cancel: "Cancel",
  yes: "Yes",
  no: "No", // toDo
  course: "Course",
  dictionary: "Dictionary",
  events: "Events",
  news: "News",
  words: "Words",
  wordsAreHere: "These words are here:",
  allWords: "All words",
  phrases: "Phrases",
  videos: "Videos",
  exercises: "Exercises",
  theory: "Theory",
  theoryAlt: "Конспект", //toDo
  theoryAdditionalFile: "Télécharger le fichier supplémentaire (%{filename})",
  topic: "Topic",
  lesson: "Lesson",
  profile: "Profile",
  orders: "Orders", // TODO fr
  payment: "Payment",
  leaderboard: "Évaluation",
  time: "Temps",
  points: "Points",
  courses: "My Courses", // TODO fr
  currentLang: {
    en: "English",
    ru: "Русский",
    fr: "Français",
    uk: "Українська",
  },
  loading: "Loading...",
  noTheory: "No theory available for this lesson",
  startTraining: "Start Training",
  missions: "Missions",
  branches: "Branches",
  easy: "Easy",
  expert: "Expert",
  wordToLearn: "Word to learn",
  iKnowWord: "I know this word",
  videoVocabulary: "Video Vocabulary",
  passed: "passed",
  unsupportedExercise: "Unsupported exercise (%{type})",
  changeCourse: "Change course",
  currentCourse: "Current course",
  allSubjects: "All subjects", //toDO
  subject: "Subject",
  group: "Group",
  start: "Start",
  chooseSubject: "Choose the subject you would like to study",
  hourValue: "%{value} h",
  completed: "Completed",
  notCompleted: "Not completed",
  paidBranch: "This branch is paid. Please purchase it to learn.",
  buyBranch: "Buy branch for %{price} %{currency}",
  paidSubject: "This subject is paid. Please purchase it to study.",
  buySubject2: "Buy subject for %{price} %{currency}",
  decksItemNotOpen: "Pour accéder a cette leçon, vous devez terminer la leçon précédente",
  decksItemNotPurchasedBranch: "The topic of this lesson is blocked",
  decksItemNotOpenLevel: "Passez le niveau précédent pour ouvrir cette leçon",
  decksItemNotOpenBranch: "Pour accéder a ce thème, vous devez terminer le thème précédent",
  deckAccessDenied: "Cette leçon n'est pas encore disponible pour vous",
  openOnDate: "ouvrira le %{date}", // todo
  levelAccessIsDenied: "Ce matériau de niveau n'est pas disponible pour vous",
  youAreDeactivated: "Votre compte a été désactivé, veuillez contacter votre école",
  lockText: {
    p1: "Malheureusement, vous ne pouvez pas accéder aux matériaux éducatifs. Les activités de l'école sont temporairement suspendues.",
    p2: "Pour des informations détaillées et la résolution de tout problème, veuillez contacter les représentants de l'école.",
  },
  youAreDeactivatedShort: "Votre compte est désactivé",
  youAreHaveNotPaidOffers: "Vous avez des offres non payées",
  yourCertificates: "Your certificates", //toDo
  emptyDeck: "Deck is Empty",
  nextDeck: "Next lesson",
  next: "Go on",
  proceed: "Procéder",
  skip: "Skip",
  more: "More",
  exit: "Sortie",
  details: "See details",
  profileEdit: "Edit user's profile",
  userInfo: "User info", //toDo
  of: "of",
  back: "Retour",
  levelPackExist: "Vous avez déjà cet ensemble de niveaux",
  awaitingPayment: "En attente de paiement",
  bought: "Achetée",
  boughtUntilDate: "Bought until  %{endDate}", // todo
  availableUntilDate: "Available until  %{endDate}", // todo
  showMore: "show more", // todo
  hide: "hide", // todo
  communication: "Связь со школой", //toDo
  askQuestion: "Ask a question", //toDo
  noDescription: "Description missing", //toDo
  onePerMonth: "1 per Month", //toDo
  semiannually: "Semiannually", //toDo
  onceAYear: "Once a year", //toDo
  stopSubscribe: "Stop Subscribe", // todo
  enable: "Enable Subscribe", // todo
  expired: "Expired", //toDo
  buySubject: "Buy Subject", //toDo
  buyOffer: "Buy offer", //toDo
  subscribeOffer: "Subscribe offer", //toDo
  firstPayment: "First payment", //toDo
  accessDaysWarning: "%{smart_count} jour restant |||| %{smart_count} jours restants",
  accessDemoDaysWarning: "Demo: %{smart_count} day left |||| Demo: %{smart_count} days left",
  closeButton: "Close",
  Exercises: {
    tabTitle: "Exercises",
    skip: "Skip",
    check: "Check",
    typeAnswer: "Type your answer",
    next: "Go on",
    rightAnswerWas: "The correct answer was:",
    congratulation: "Congratulation!",
    congratText: "You have learned all exercises in this lesson!",
    wrongAnswer: "Votre réponse est fausse",
    answerSaved: "réponse sauvegardée", // todo
    commentToAnswer: "comment to selected answer:", // todo
    appendix: {
      pdf: {
        tooltipButton: "Additional stuff", // todo
      },
    },
    type11: {
      title: {
        select: "Choisissez la bonne réponse",
        keyboard: "Écrivez la bonne réponse",
      },
    },
    type9: {
      title: {
        select: "Fill in the gaps by choosing words",
        keyboard: "Fill in the gaps",
      },
    },
    type8: {
      title: "Find matches",
    },
    type7: {
      title: {
        text: "Write your answer",
        audio: "Record your answer",
      },
      recordingIsNotSupported: "Audio recording is not supported",
      audioRecordingError: "The following getUserMedia error occurred: ",
      inputLabel: "Message",
      addPhoto: "Add photo (max 5)",
      cameraInitialization: "Initialization...",
      change: "Change",
      codeToApprove: "Mot de passe", //toDo
      homework: {
        teacherData: {
          audio: "Audio teacher's comment", // todo
          audioComment: "Voice teacher's comment", // todo
        },
      },
      addFiles: "Ajouter des fichiers", // todo
    },
    type6: {
      title: "Dessinez une lettre",
      checkButtonText: "Got it!",
    },
    type3: {
      title: "Drag&Drop the elements in order",
      hint: "Drag here items placed below",
    },
    type5: {
      title: "Select elements",
      hint: "Select the correct elements from the bottom of the screen (to choose - just click on them)",
    },
    type2: {
      title: "Choose the missing element",
    },
  },
  Events: {
    headNotice: "The event time is displayed according to your time zone", // todo
    title: "This week's events", // todo
    startTime: "Start time",
    group: "Group",
    persons: "%{smart_count} person |||| %{smart_count} persons",
    leader: "Leader", //toDo
    tag: "Tag", //toDo
    joinedCheckbox: "Uniquement joint", //toDo
    joined: "Rejoint:", //toDo
    interested: "Inscription",
    enter: "Enter", // todo
    limitPopupInfo: "You have reached the registration limit", //toDo
    eventHasStarted: "Подія вже почалася!", //toDo
    registrationNotice: "Ви успішно зареєструвалися! Кнопка входу буде активна у %{date_and_time}. Після цього часу ви не зможете скасувати реєстрацію на подію", //toDo
    registrationNoticeNoUrl: "Ви успішно зареєструвалися! Після %{date_and_time} ви не зможете скасувати реєстрацію на подію", //toDo
    cancelRegistration: "Ви дійсно хочете скасувати реєстрацію?", //toDo
    calendarLink: "Add Event to Google Calendar", //toDo
  },
  statistic: {
    title: "Statistics",
    points: "points",
    phrases: "phrases",
    missions: "missions",
    words: "words",
    time: "time",
  },
  status: {
    wait_review: "En attente de vérification",
    on_review: "En examen",
    approved: "APPROUVÉ",
    not_approved: "NON APPROUVÉ",
    not_approved_allow_update: "Non approuvé (permettre la modification)",
    ai_reviewed: "Testé avec l'IA", // todo
  },
  homeworks: {
    tabName: "Homeworks",
    todo: "To do",
    done: "Done",
    topic: "Sujet",
    lesson: "Leçon",
    onlyInMobileApp: "Disponible uniquement dans une application mobile",
    todoHomework: "PENDING HOMEWORK",
    postToDoButton: "Send",
    types: {
      homework_todo: "ToDo", // todo
      exercise: "Exercise", // todo
    },
  },
  exam: {
    popupTitle: "Lesson with exam", //toDo
    popupDescr1: "Ceci est une leçon avec un examen. Tout d'abord, étudiez tous les matériaux supplémentaires et ensuite seulement passez aux exercices.", //toDo
    popupDescr2: `Dès que vous accédez à l'onglet "Exercices", le compte à rebours commence.`, //toDo
    popupDescr3: 'Les onglets "Théorie" et "Vidéo" ne démarrent PAS le compte à rebours de l\'examen.', //toDo
    cancelButton: "Next time",
    positiveButton: "Il est clair",
    questions: "%{smart_count} question |||| %{smart_count} questions",
    oneAttempt: "One try per question",
    timeLimit: "Limited time: %{limit} min",
    noPause: "The exam cannot be paused",
    progress: "Question #%{current} of %{total}",
    closeButton: "Close",
    nextButton: "Next",
    finishedPopupTitle: "Final score",
    examPassed: "Exam passed",
    examNotPassed: "Exam not passed",
    waitHomeworks: "%{smart_count} question on review |||| %{smart_count} questions on review",
  },
  subjects: {
    unavailable: "Le sujet est actuellement indisponible",
  },
  orderStatus: {
    approved: "Approved", // TODO
    canceled: "Canceled", // TODO
    pending: "Pending", // TODO
  },
  notificationsTypes: {
    event: "event", //todo
    invoice: "invoice", //todo
    news: "news", //todo
    homework: "homework", //todo
    homework_todo: "homework_todo", //todo
    type: {
      exercise: "Homework", //todo
      homework_todo: "Homework To do", //todo
    },
  },
  notificationsAlert: {
    subscribe: "Active les notifications",
    unsubscribe: "Désactiver les notifications",
    instructions: "Cliquez sur le cadenas 🔒 à gauche de la barre d'adresse et modifiez la résolution des notifications comme indiqué dans l'image ou modifiez les paramètres généraux de votre navigateur.",
    permissionDefault: {
      title: "S'abonner aux notifications 🔔",
      body: "Alertes sur le changement d'état des devoirs, le début d'un événement, les actualités, une nouvelle tâche à exécuter. Pas de publicité, juste des notifications sur votre apprentissage !",
    },
    permissionDenied: {
      title: "Les notifications sont désormais désactivées ❌",
      body: "Vous avez précédemment désactivé les notifications. Sans elles, vous ne pouvez pas être informé à temps d'un changement d'état des Devoirs, du début de l'événement. Pour activer les notifications, suivez les instructions ci-dessous :",
    },
    permissionGranted: {
      title: "Les notifications sont maintenant activées ✔",
      body: "Êtes-vous sûr de vouloir renoncer aux notifications ? Sans elles, vous ne pouvez pas être informé à temps d'un changement de statut d'un devoir ou du début d'un événement. Pour désactiver les notifications, suivez les instructions ci-dessous :",
    },
  },
  feedbackDialog: {
    sendFeedback: "Send feedback", // todo
    placeholder: "Tell us about your impressions", // todo
    defaultInstruction: "Utilisez le curseur pour évaluer", // todo
    eventInstruction: "Évaluez le déroulement de l'événement", // todo
    submitError: "Veuillez sélectionner une note", // todo
    submitSuccess: "Nous vous remercions de votre réponse. Votre commentaire a été envoyé avec succès", // todo
    wasNotPresent: "Je n'ai pas participé à l'événement", // todo
    dateOfEvent: "Date de l'événement:", // todo
  },
  congratulation: {
    mission: "Félicitations, la mission de %{name} est terminée!",
  },
};
